body {
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.2;
}

.g-font-nunito-sans {
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings:
      "wdth" 100,
      "YTLC" 500;
  }

.app-container {
    max-width: 600px;
}

/* Nav */
.app-nav {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
}

.app-nav-active {
    background: var(--bs-warning) !important;
    color: var(--bs-body-color) !important;
}

/* Menu */
.app-menu .dropdown-toggle::after {
    display: none;
}

.form-visitas.d-none+button {
    border-radius: var(--bs-border-radius) !important;
}

/* Toast */
.app-toast {
    position: fixed;
    top: 5px;
    left: 5px;
    right: 5px;
    border-radius: .375rem;
    z-index: 4900;
    cursor: pointer;
}

.app-toast~.app-toast {
    top: 70px;
}

/* Loading */
.app-loading .item-load {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0 !important;
}

.app-loading .item-load:before,
.app-loading .item-load:after {
    content: '';
    position: absolute;
    inset: 0;
}

.app-loading .item-load:before {
    background-color: #fff;
    z-index: 10;
}

.app-loading .item-load:after {
    background-color: var(--bs-secondary-bg-subtle);
    border-radius: var(--bs-border-radius) !important;
    background: linear-gradient(90deg,
            rgba(224, 224, 224, 0) 0%,
            rgba(224, 224, 224, 0.8) 50%,
            rgba(224, 224, 224, 0) 100%);
    animation: shimmer 2s infinite;
    z-index: 11;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

/* List */
.list-list .list-group-item:hover,
.list-list .list-group-item:focus {
    background-color: var(--bs-secondary-bg);
}

.filter-active {
    background: var(--bs-warning);
    border-color: transparent !important;
    color: var(--bs-dark) !important;
}

.app-filter-options-wrap.d-none+div {
    padding-top: .25rem !important;
}

.product-prices .form-check {
    min-height: auto;
}

.btn-circle {
    width: 58px;
    height: 58px;
}

#product-discount {
    width: 50px;
    appearance: none;
}

#product-discount-slider {
    height: 2rem;
}

#product-discount-slider::-webkit-slider-thumb,
#product-discount-slider::-moz-range-thumb,
#product-discount-slider::-ms-thumb {
    height: 32px;
    width: 20px;
}

#product-discount-slider::-webkit-slider-thumb {
    margin-top: -11px;
}

/* Login */
.app-login {
    position: fixed;
    inset: 0;
    z-index: 4000;
    background-image: url('../images/bg_ver.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.app-login:before {
    content: '';
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.35);
    z-index: 1;
}

.app-login-wrap {
    z-index: 2;
}

.app-pin-pad {
    max-width: 250px;
}

.app-pin-pad::placeholder {
    color: #fff;
}

.app-pin-type label,
.app-pin-type label:hover {
    --bs-btn-bg: #fff;
    border-color: #fff;
}

/* Utilities */
.app-block:after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4100;
}

.cursor-pointer {
    cursor: pointer;
}

.fs-7 {
    font-size: .875em;
    ;
}

/* Plugins */
.ptr--ptr {
    background: var(--bs-black) !important;
    box-shadow: inset 0 -3px 4px rgba(0, 0, 0, 0.9) !important;
}

.ptr--text,
.ptr--icon {
    color: white !important
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background: none;
    padding-right: 0.75rem;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: none;
}

@media (width >=768px) {
    .app-login {
        background-image: url('../images/bg_hor.jpg');
    }

    /* Toast */
    .app-toast {
        left: 0;
        right: 0;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
}