body {
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

.g-font-nunito-sans {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100, "YTLC" 500;
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.app-container {
  max-width: 600px;
}

.app-nav {
  box-shadow: 0 1px 1px #000000bf;
}

.app-nav-active {
  background: var(--bs-warning) !important;
  color: var(--bs-body-color) !important;
}

.app-menu .dropdown-toggle:after {
  display: none;
}

.form-visitas.d-none + button {
  border-radius: var(--bs-border-radius) !important;
}

.app-toast {
  z-index: 4900;
  cursor: pointer;
  border-radius: .375rem;
  position: fixed;
  top: 5px;
  left: 5px;
  right: 5px;
}

.app-toast ~ .app-toast {
  top: 70px;
}

.app-loading .item-load {
  z-index: 1;
  position: relative;
  overflow: hidden;
  border-radius: 0 !important;
}

.app-loading .item-load:before, .app-loading .item-load:after {
  content: "";
  position: absolute;
  inset: 0;
}

.app-loading .item-load:before {
  z-index: 10;
  background-color: #fff;
}

.app-loading .item-load:after {
  background-color: var(--bs-secondary-bg-subtle);
  z-index: 11;
  background: linear-gradient(90deg, #e0e0e000 0%, #e0e0e0cc 50%, #e0e0e000 100%);
  animation: 2s infinite shimmer;
  border-radius: var(--bs-border-radius) !important;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.list-list .list-group-item:hover, .list-list .list-group-item:focus {
  background-color: var(--bs-secondary-bg);
}

.filter-active {
  background: var(--bs-warning);
  color: var(--bs-dark) !important;
  border-color: #0000 !important;
}

.app-filter-options-wrap.d-none + div {
  padding-top: .25rem !important;
}

.product-prices .form-check {
  min-height: auto;
}

.btn-circle {
  width: 58px;
  height: 58px;
}

#product-discount {
  appearance: none;
  width: 50px;
}

#product-discount-slider {
  height: 2rem;
}

#product-discount-slider::-webkit-slider-thumb {
  width: 20px;
  height: 32px;
}

#product-discount-slider::-moz-range-thumb {
  width: 20px;
  height: 32px;
}

#product-discount-slider::-ms-thumb {
  width: 20px;
  height: 32px;
}

#product-discount-slider::-webkit-slider-thumb {
  margin-top: -11px;
}

.app-login {
  z-index: 4000;
  background-image: url("bg_ver.a61de0d7.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  inset: 0;
}

.app-login:before {
  content: "";
  z-index: 1;
  background: #00000059;
  position: fixed;
  inset: 0;
}

.app-login-wrap {
  z-index: 2;
}

.app-pin-pad {
  max-width: 250px;
}

.app-pin-pad::placeholder {
  color: #fff;
}

.app-pin-type label, .app-pin-type label:hover {
  --bs-btn-bg: #fff;
  border-color: #fff;
}

.app-block:after {
  content: "";
  z-index: 4100;
  position: fixed;
  inset: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-7 {
  font-size: .875em;
}

.ptr--ptr {
  background: var(--bs-black) !important;
  box-shadow: inset 0 -3px 4px #000000e6 !important;
}

.ptr--text, .ptr--icon {
  color: #fff !important;
}

.form-control.is-valid, .was-validated .form-control:valid, .form-control.is-invalid, .was-validated .form-control:invalid {
  background: none;
  padding-right: .75rem;
}

.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: none;
}

@media (width >= 768px) {
  .app-login {
    background-image: url("bg_hor.cb5beab6.jpg");
  }

  .app-toast {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}
/*# sourceMappingURL=index.46c6fdee.css.map */
